.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo {
  width: 112px;
  height: 36px;
  margin: 30px auto;
}

p:nth-child(2) {
  
  text-align: center;
  
}

p:nth-child(3) {
  
  text-align: center;
  
}

.login {
  height: 300px;
  width: 350px;
  margin: auto;
  background-color: #F7F7F7;
  box-shadow: 0px 1px 1px 0px grey;
}

.login > div:nth-child(3) {
  width: 100px;
  margin: auto;
}

.login > div:nth-child(3) > img {
  height: 100px;
  border-radius: 100%;
}

input {
  height: 35px;
  width: 250px;
  margin-left: 48px;
  font-size: 20px;
}

.button {
  display: inline-block;
  height: 40px;
  width: 250px;
  margin-left: 49px;
  text-align: center;
  background-color:dodgerblue;
  border: 1px solid blue;
  color: white;
  font-weight: bold;
}

.login > a:last-child {
  display: inline-block;
  float: right;
  margin-right: 49px;
  font-size: 14px;
  text-decoration: none;
  color: mediumblue;
}

.login >a:last-child:hover {
  text-decoration: underline;
}

p:nth-child(5) {
  text-align: center;
}

p > a {
  text-decoration: none;
  color: mediumblue;
}

p > a:hover {
  text-decoration: underline;
}

p:nth-child(6) {
  text-align: center;
}

.logo-strip {
  width: 230px;
  margin: -10px auto;
}

.bottom {
  height: 35px;
  width: 100%;
  border-top: 0.5px solid #E5E5E5;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  background-color: white;
}

.bottom a {
  margin-left: 24px;
  text-decoration: none;
}

.bottom a:hover {
  text-decoration: underline;
}

#DIV_1 {
    block-size: 136px;
    box-sizing: border-box;
    height: 136px;
    inline-size: 823px;
    perspective-origin: 411.5px 68px;
    text-size-adjust: 100%;
    transform-origin: 411.5px 68px;
    width: 823px;
    font: 16px / 24px SunOT;
}/*#DIV_1*/

#DIV_1:after {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_1:after*/

#DIV_1:before {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_1:before*/

#DIV_2, #DIV_9 {
    block-size: 68px;
    box-sizing: border-box;
    display: flex;
    height: 68px;
    inline-size: 823px;
    justify-content: space-between;
    perspective-origin: 411.5px 34px;
    text-size-adjust: 100%;
    transform-origin: 411.5px 34px;
    width: 823px;
    font: 16px / 24px SunOT;
}/*#DIV_2, #DIV_9*/

#DIV_2:after, #DIV_9:after {
    box-sizing: border-box;
    display: block;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_2:after, #DIV_9:after*/

#DIV_2:before, #DIV_9:before {
    box-sizing: border-box;
    display: block;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_2:before, #DIV_9:before*/

#MAT-LABEL_3, #MAT-LABEL_10 {
    block-size: 48px;
    border-block-end-color: rgb(0, 103, 130);
    border-block-start-color: rgb(0, 103, 130);
    border-inline-end-color: rgb(0, 103, 130);
    border-inline-start-color: rgb(0, 103, 130);
    box-sizing: border-box;
    caret-color: rgb(0, 103, 130);
    color: rgb(0, 103, 130);
    column-rule-color: rgb(0, 103, 130);
    display: block;
    height: 48px;
    inline-size: 411.5px;
    margin-block-end: 10px;
    margin-block-start: 10px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    padding-block-start: 16px;
    perspective-origin: 205.75px 24px;
    text-decoration: none solid rgb(0, 103, 130);
    text-emphasis-color: rgb(0, 103, 130);
    text-size-adjust: 100%;
    transform-origin: 205.75px 24px;
    width: 411.5px;
    border: 0px none rgb(0, 103, 130);
    flex: 0 0 50%;
    font: 500 16px / 24px SunOT;
    margin: 10px 0px;
    outline: rgb(0, 103, 130) none 0px;
    padding: 16px 0px 0px;
}/*#MAT-LABEL_3, #MAT-LABEL_10*/

#MAT-LABEL_3:after, #MAT-LABEL_10:after {
    border-block-end-color: rgb(0, 103, 130);
    border-block-start-color: rgb(0, 103, 130);
    border-inline-end-color: rgb(0, 103, 130);
    border-inline-start-color: rgb(0, 103, 130);
    box-sizing: border-box;
    caret-color: rgb(0, 103, 130);
    color: rgb(0, 103, 130);
    column-rule-color: rgb(0, 103, 130);
    text-decoration: none solid rgb(0, 103, 130);
    text-emphasis-color: rgb(0, 103, 130);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 103, 130);
    font: 500 16px / 24px SunOT;
    outline: rgb(0, 103, 130) none 0px;
}/*#MAT-LABEL_3:after, #MAT-LABEL_10:after*/

#MAT-LABEL_3:before, #MAT-LABEL_10:before {
    border-block-end-color: rgb(0, 103, 130);
    border-block-start-color: rgb(0, 103, 130);
    border-inline-end-color: rgb(0, 103, 130);
    border-inline-start-color: rgb(0, 103, 130);
    box-sizing: border-box;
    caret-color: rgb(0, 103, 130);
    color: rgb(0, 103, 130);
    column-rule-color: rgb(0, 103, 130);
    text-decoration: none solid rgb(0, 103, 130);
    text-emphasis-color: rgb(0, 103, 130);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 103, 130);
    font: 500 16px / 24px SunOT;
    outline: rgb(0, 103, 130) none 0px;
}/*#MAT-LABEL_3:before, #MAT-LABEL_10:before*/

#CORE-FORM-INPUT_4, #CORE-FORM-INPUT_11 {
    block-size: 68px;
    box-sizing: border-box;
    display: block;
    height: 68px;
    inline-size: 411.5px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    perspective-origin: 205.75px 34px;
    text-size-adjust: 100%;
    transform-origin: 205.75px 34px;
    width: 411.5px;
    font: 16px / 24px SunOT;
}/*#CORE-FORM-INPUT_4, #CORE-FORM-INPUT_11*/

#CORE-FORM-INPUT_4:after, #CORE-FORM-INPUT_11:after {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#CORE-FORM-INPUT_4:after, #CORE-FORM-INPUT_11:after*/

#CORE-FORM-INPUT_4:before, #CORE-FORM-INPUT_11:before {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#CORE-FORM-INPUT_4:before, #CORE-FORM-INPUT_11:before*/

#DIV_5, #DIV_12 {
    align-items: center;
    block-size: 68px;
    box-sizing: border-box;
    display: flex;
    height: 68px;
    inline-size: 411.5px;
    justify-content: space-between;
    padding-block-end: 10px;
    padding-block-start: 10px;
    perspective-origin: 205.75px 34px;
    text-size-adjust: 100%;
    transform-origin: 205.75px 34px;
    width: 411.5px;
    font: 16px / 24px SunOT;
    padding: 10px 0px;
}/*#DIV_5, #DIV_12*/

#DIV_5:after, #DIV_12:after {
    box-sizing: border-box;
    display: block;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_5:after, #DIV_12:after*/

#DIV_5:before, #DIV_12:before {
    box-sizing: border-box;
    display: block;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_5:before, #DIV_12:before*/

#SPAN_6, #SPAN_13 {
    block-size: 48px;
    box-sizing: border-box;
    display: block;
    height: 48px;
    inline-size: 411.5px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    perspective-origin: 205.75px 24px;
    text-size-adjust: 100%;
    transform-origin: 205.75px 24px;
    width: 411.5px;
    flex: 1 0 45%;
    font: 16px / 24px SunOT;
}/*#SPAN_6, #SPAN_13*/

#SPAN_6:after, #SPAN_13:after {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#SPAN_6:after, #SPAN_13:after*/

#SPAN_6:before, #SPAN_13:before {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#SPAN_6:before, #SPAN_13:before*/

#INPUT_7, #INPUT_14 {
    appearance: none;
    block-size: 48px;
    border-block-end-color: rgb(183, 200, 200);
    border-block-end-style: solid;
    border-block-end-width: 1px;
    border-block-start-color: rgb(183, 200, 200);
    border-block-start-style: solid;
    border-block-start-width: 1px;
    border-end-end-radius: 4px;
    border-end-start-radius: 4px;
    border-inline-end-color: rgb(183, 200, 200);
    border-inline-end-style: solid;
    border-inline-end-width: 1px;
    border-inline-start-color: rgb(183, 200, 200);
    border-inline-start-style: solid;
    border-inline-start-width: 1px;
    border-start-end-radius: 4px;
    border-start-start-radius: 4px;
    caret-color: rgb(0, 103, 130);
    color: rgb(0, 103, 130);
    column-rule-color: rgb(0, 103, 130);
    height: 48px;
    inline-size: 288px;
    max-inline-size: 288px;
    max-width: 288px;
    padding-inline-start: 16px;
    perspective-origin: 144px 24px;
    text-decoration: none solid rgb(0, 103, 130);
    text-emphasis-color: rgb(0, 103, 130);
    text-size-adjust: 100%;
    transform-origin: 144px 24px;
    width: 288px;
    background: rgb(245, 245, 245) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 1px solid rgb(183, 200, 200);
    border-radius: 4px;
    font: 16px / 24px SunOT;
    outline: rgb(0, 103, 130) none 0px;
    padding: 1px 2px 1px 16px;
}/*#INPUT_7, #INPUT_14*/

#INPUT_7:after, #INPUT_14:after {
    border-block-end-color: rgb(0, 103, 130);
    border-block-start-color: rgb(0, 103, 130);
    border-inline-end-color: rgb(0, 103, 130);
    border-inline-start-color: rgb(0, 103, 130);
    box-sizing: border-box;
    caret-color: rgb(0, 103, 130);
    color: rgb(0, 103, 130);
    column-rule-color: rgb(0, 103, 130);
    text-decoration: none solid rgb(0, 103, 130);
    text-emphasis-color: rgb(0, 103, 130);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 103, 130);
    font: 16px / 24px SunOT;
    outline: rgb(0, 103, 130) none 0px;
}/*#INPUT_7:after, #INPUT_14:after*/

#INPUT_7:before, #INPUT_14:before {
    border-block-end-color: rgb(0, 103, 130);
    border-block-start-color: rgb(0, 103, 130);
    border-inline-end-color: rgb(0, 103, 130);
    border-inline-start-color: rgb(0, 103, 130);
    box-sizing: border-box;
    caret-color: rgb(0, 103, 130);
    color: rgb(0, 103, 130);
    column-rule-color: rgb(0, 103, 130);
    text-decoration: none solid rgb(0, 103, 130);
    text-emphasis-color: rgb(0, 103, 130);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 103, 130);
    font: 16px / 24px SunOT;
    outline: rgb(0, 103, 130) none 0px;
}/*#INPUT_7:before, #INPUT_14:before*/

#DIV_8 {
    block-size: 2px;
    bottom: 415px;
    box-sizing: border-box;
    height: 2px;
    inline-size: 278px;
    inset-block-end: 415px;
    inset-block-start: 146px;
    inset-inline-end: 171.5px;
    inset-inline-start: 449.5px;
    left: 449.5px;
    padding-block-start: 2px;
    perspective-origin: 139px 1px;
    position: absolute;
    right: 171.5px;
    text-size-adjust: 100%;
    top: 146px;
    transform-origin: 139px 1px;
    vertical-align: middle;
    width: 278px;
    font: 16px / 24px SunOT;
    padding: 2px 0px 0px;
}/*#DIV_8*/

#DIV_8:after {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_8:after*/

#DIV_8:before {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_8:before*/

#DIV_15 {
    block-size: 2px;
    bottom: 347px;
    box-sizing: border-box;
    height: 2px;
    inline-size: 278px;
    inset-block-end: 347px;
    inset-block-start: 214px;
    inset-inline-end: 171.5px;
    inset-inline-start: 449.5px;
    left: 449.5px;
    padding-block-start: 2px;
    perspective-origin: 139px 1px;
    position: absolute;
    right: 171.5px;
    text-size-adjust: 100%;
    top: 214px;
    transform-origin: 139px 1px;
    vertical-align: middle;
    width: 278px;
    font: 16px / 24px SunOT;
    padding: 2px 0px 0px;
}/*#DIV_15*/

#DIV_15:after {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_15:after*/

#DIV_15:before {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font: 16px / 24px SunOT;
}/*#DIV_15:before*/

#DIV_1 {
    block-size: 562.766px;
    height: 562.766px;
    inline-size: 898px;
    perspective-origin: 449px 281.375px;
    transform-origin: 449px 281.383px;
    width: 898px;
    font: 16px Times;
}/*#DIV_1*/

#DIV_1:after {
    font: 16px Times;
}/*#DIV_1:after*/

#DIV_1:before {
    font: 16px Times;
}/*#DIV_1:before*/

#BR_2, #BR_3, #BR_5, #BR_20, #BR_22 {
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    font: 16px Times;
}/*#BR_2, #BR_3, #BR_5, #BR_20, #BR_22*/

#BR_2:after, #BR_3:after, #BR_5:after, #BR_20:after, #BR_22:after {
    font: 16px Times;
}/*#BR_2:after, #BR_3:after, #BR_5:after, #BR_20:after, #BR_22:after*/

#BR_2:before, #BR_3:before, #BR_5:before, #BR_20:before, #BR_22:before {
    font: 16px Times;
}/*#BR_2:before, #BR_3:before, #BR_5:before, #BR_20:before, #BR_22:before*/

#BR_4, #BR_23 {
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    font: 16px Times;
}/*#BR_4, #BR_23*/

#BR_4:after, #BR_23:after {
    font: 16px Times;
}/*#BR_4:after, #BR_23:after*/

#BR_4:before, #BR_23:before {
    font: 16px Times;
}/*#BR_4:before, #BR_23:before*/

#CENTER_6 {
    block-size: 74px;
    height: 74px;
    inline-size: 898px;
    perspective-origin: 449px 37px;
    transform-origin: 449px 37px;
    width: 898px;
    font: 16px Times;
}/*#CENTER_6*/

#CENTER_6:after {
    font: 16px Times;
}/*#CENTER_6:after*/

#CENTER_6:before {
    font: 16px Times;
}/*#CENTER_6:before*/

#IMG_7 {
    block-size: 74px;
    height: 74px;
    inline-size: 76px;
    perspective-origin: 38px 37px;
    text-align: -webkit-center;
    transform-origin: 38px 37px;
    width: 76px;
    font: 16px Times;
}/*#IMG_7*/

#IMG_7:after {
    text-align: -webkit-center;
    font: 16px Times;
}/*#IMG_7:after*/

#IMG_7:before {
    text-align: -webkit-center;
    font: 16px Times;
}/*#IMG_7:before*/

#H1_8 {
    block-size: 53.5938px;
    height: 53.5938px;
    inline-size: 898px;
    margin-block-end: 24px;
    margin-block-start: 26.8px;
    perspective-origin: 449px 26.7969px;
    text-align: center;
    transform-origin: 449px 26.7969px;
    width: 898px;
    font: 300 40px / 53.6px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    margin: 26.8px 0px 24px;
}/*#H1_8*/

#H1_8:after {
    text-align: center;
    font: 300 40px / 53.6px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}/*#H1_8:after*/

#H1_8:before {
    text-align: center;
    font: 300 40px / 53.6px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}/*#H1_8:before*/

#H2_9 {
    block-size: 19.1875px;
    height: 19.1875px;
    inline-size: 898px;
    margin-block-end: 24px;
    margin-block-start: 13.28px;
    perspective-origin: 449px 9.59375px;
    text-align: center;
    transform-origin: 449px 9.59375px;
    width: 898px;
    font: 200 16px / 19.2px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    margin: 13.28px 0px 24px;
}/*#H2_9*/

#H2_9:after {
    text-align: center;
    font: 200 16px / 19.2px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}/*#H2_9:after*/

#H2_9:before {
    text-align: center;
    font: 200 16px / 19.2px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}/*#H2_9:before*/

#DIV_10 {
    block-size: 19.1875px;
    height: 19.1875px;
    inline-size: 898px;
    margin-block-end: 24px;
    perspective-origin: 449px 9.59375px;
    text-align: center;
    transform-origin: 449px 9.59375px;
    width: 898px;
    font: 200 16px / 19.2px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    margin: 0px 0px 24px;
}/*#DIV_10*/

#DIV_10:after {
    text-align: center;
    font: 200 16px / 19.2px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}/*#DIV_10:after*/

#DIV_10:before {
    text-align: center;
    font: 200 16px / 19.2px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}/*#DIV_10:before*/

#DIV_11, #DIV_12, #DIV_15 {
    block-size: 226px;
    height: 226px;
    inline-size: 898px;
    perspective-origin: 449px 113px;
    transform-origin: 449px 113px;
    width: 898px;
    font: 16px Times;
}/*#DIV_11, #DIV_12, #DIV_15*/

#DIV_11:after, #DIV_12:after, #DIV_15:after {
    font: 16px Times;
}/*#DIV_11:after, #DIV_12:after, #DIV_15:after*/

#DIV_11:before, #DIV_12:before, #DIV_15:before {
    font: 16px Times;
}/*#DIV_11:before, #DIV_12:before, #DIV_15:before*/

#DIV_13 {
    block-size: 226px;
    height: 226px;
    inline-size: 898px;
    perspective-origin: 449px 113px;
    transform-origin: 449px 113px;
    width: 898px;
    font: 16px Times;
}/*#DIV_13*/

#DIV_13:after {
    font: 16px Times;
}/*#DIV_13:after*/

#DIV_13:before {
    font: 16px Times;
}/*#DIV_13:before*/

#FORM_14 {
    block-size: 226px;
    height: 226px;
    inline-size: 898px;
    margin-block-end: 16px;
    perspective-origin: 449px 113px;
    transform-origin: 449px 113px;
    width: 898px;
    font: 16px Times;
    margin: 0px 0px 16px;
}/*#FORM_14*/

#FORM_14:after {
    font: 16px Times;
}/*#FORM_14:after*/

#FORM_14:before {
    font: 16px Times;
}/*#FORM_14:before*/

#INPUT_16, #INPUT_19 {
    appearance: none;
    border-block-end-color: rgb(0, 0, 0);
    border-block-end-style: none;
    border-block-end-width: 0px;
    border-block-start-color: rgb(0, 0, 0);
    border-block-start-style: none;
    border-block-start-width: 0px;
    border-inline-end-color: rgb(0, 0, 0);
    border-inline-end-style: none;
    border-inline-end-width: 0px;
    border-inline-start-color: rgb(0, 0, 0);
    border-inline-start-style: none;
    border-inline-start-width: 0px;
    cursor: default;
    display: none;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-inline-end: 0px;
    padding-inline-start: 0px;
    background: rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(0, 0, 0);
    padding: 0px;
}/*#INPUT_16, #INPUT_19*/

#INPUT_16:after, #INPUT_19:after {
    cursor: default;
}/*#INPUT_16:after, #INPUT_19:after*/

#INPUT_16:before, #INPUT_19:before {
    cursor: default;
}/*#INPUT_16:before, #INPUT_19:before*/

#INPUT_17, #INPUT_18 {
    block-size: 42px;
    border-block-end-color: rgba(0, 0, 0, 0.2);
    border-block-end-style: solid;
    border-block-end-width: 1px;
    border-block-start-color: rgba(0, 0, 0, 0.2);
    border-block-start-style: solid;
    border-block-start-width: 1px;
    border-end-end-radius: 4px;
    border-end-start-radius: 4px;
    border-inline-end-color: rgba(0, 0, 0, 0.2);
    border-inline-end-style: solid;
    border-inline-end-width: 1px;
    border-inline-start-color: rgba(0, 0, 0, 0.2);
    border-inline-start-style: solid;
    border-inline-start-width: 1px;
    border-start-end-radius: 4px;
    border-start-start-radius: 4px;
    display: block;
    height: 42px;
    inline-size: 286px;
    margin-block-end: 12px;
    margin-inline-end: 306px;
    margin-inline-start: 306px;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-inline-end: 16px;
    padding-inline-start: 16px;
    perspective-origin: 143px 21px;
    transform-origin: 143px 21px;
    width: 286px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font: 17px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    margin: 0px 306px 12px;
    padding: 0px 16px;
}/*#INPUT_17, #INPUT_18*/

#INPUT_17:after, #INPUT_18:after {
    font: 17px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}/*#INPUT_17:after, #INPUT_18:after*/

#INPUT_17:before, #INPUT_18:before {
    font: 17px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}/*#INPUT_17:before, #INPUT_18:before*/

#BUTTON_21 {
    align-items: center;
    block-size: 26px;
    border-block-end-color: rgb(0, 132, 255);
    border-block-end-style: none;
    border-block-end-width: 0px;
    border-block-start-color: rgb(0, 132, 255);
    border-block-start-style: none;
    border-block-start-width: 0px;
    border-inline-end-color: rgb(0, 132, 255);
    border-inline-end-style: none;
    border-inline-end-width: 0px;
    border-inline-start-color: rgb(0, 132, 255);
    border-inline-start-style: none;
    border-inline-start-width: 0px;
    caret-color: rgb(0, 132, 255);
    color: rgb(0, 132, 255);
    column-rule-color: rgb(0, 132, 255);
    cursor: pointer;
    display: flex;
    height: 26px;
    inline-size: 116.922px;
    margin-block-start: 5px;
    margin-inline-end: 390.547px;
    margin-inline-start: 390.531px;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-inline-end: 10px;
    padding-inline-start: 10px;
    perspective-origin: 58.4531px 13px;
    text-decoration: none solid rgb(0, 132, 255);
    text-emphasis-color: rgb(0, 132, 255);
    text-overflow: ellipsis;
    transform-origin: 58.4609px 13px;
    white-space: nowrap;
    width: 116.922px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(0, 132, 255);
    font: 24px / 26px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    margin: 5px 390.547px 0px 390.531px;
    outline: rgb(0, 132, 255) none 0px;
    overflow: hidden;
    padding: 0px 10px;
}/*#BUTTON_21*/

#BUTTON_21:after {
    border-block-end-color: rgb(0, 132, 255);
    border-block-start-color: rgb(0, 132, 255);
    border-inline-end-color: rgb(0, 132, 255);
    border-inline-start-color: rgb(0, 132, 255);
    caret-color: rgb(0, 132, 255);
    color: rgb(0, 132, 255);
    column-rule-color: rgb(0, 132, 255);
    cursor: pointer;
    display: block;
    text-decoration: none solid rgb(0, 132, 255);
    text-emphasis-color: rgb(0, 132, 255);
    white-space: nowrap;
    border: 0px none rgb(0, 132, 255);
    font: 24px / 26px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    outline: rgb(0, 132, 255) none 0px;
}/*#BUTTON_21:after*/

#BUTTON_21:before {
    border-block-end-color: rgb(0, 132, 255);
    border-block-start-color: rgb(0, 132, 255);
    border-inline-end-color: rgb(0, 132, 255);
    border-inline-start-color: rgb(0, 132, 255);
    caret-color: rgb(0, 132, 255);
    color: rgb(0, 132, 255);
    column-rule-color: rgb(0, 132, 255);
    cursor: pointer;
    display: block;
    text-decoration: none solid rgb(0, 132, 255);
    text-emphasis-color: rgb(0, 132, 255);
    white-space: nowrap;
    border: 0px none rgb(0, 132, 255);
    font: 24px / 26px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    outline: rgb(0, 132, 255) none 0px;
}/*#BUTTON_21:before*/

#DIV_24 {
    block-size: 17px;
    height: 17px;
    inline-size: 898px;
    perspective-origin: 449px 8.5px;
    transform-origin: 449px 8.5px;
    width: 898px;
    font: 16px Times;
}/*#DIV_24*/

#DIV_24:after {
    font: 16px Times;
}/*#DIV_24:after*/

#DIV_24:before {
    font: 16px Times;
}/*#DIV_24:before*/

#DIV_25 {
    block-size: 17px;
    height: 17px;
    inline-size: 538.812px;
    margin-inline-start: 359.188px;
    perspective-origin: 269.406px 8.5px;
    transform-origin: 269.406px 8.5px;
    width: 538.812px;
    font: 16px Times;
    margin: 0px 0px 0px 359.188px;
}/*#DIV_25*/

#DIV_25:after {
    font: 16px Times;
}/*#DIV_25:after*/

#DIV_25:before {
    font: 16px Times;
}/*#DIV_25:before*/

#P_26 {
    block-size: 17px;
    height: 17px;
    inline-size: 538.812px;
    margin-block-end: 27px;
    margin-block-start: 16px;
    perspective-origin: 269.406px 8.5px;
    transform-origin: 269.406px 8.5px;
    width: 538.812px;
    font: 16px Times;
    margin: 16px 0px 27px;
}/*#P_26*/

#P_26:after {
    font: 16px Times;
}/*#P_26:after*/

#P_26:before {
    font: 16px Times;
}/*#P_26:before*/

#LABEL_27 {
    block-size: 17px;
    border-block-end-color: rgb(146, 146, 146);
    border-block-start-color: rgb(146, 146, 146);
    border-inline-end-color: rgb(146, 146, 146);
    border-inline-start-color: rgb(146, 146, 146);
    bottom: 0px;
    caret-color: rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    column-rule-color: rgb(146, 146, 146);
    cursor: pointer;
    display: block;
    height: 17px;
    inline-size: 503.812px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    margin-block-end: 12px;
    padding-inline-start: 35px;
    perspective-origin: 269.406px 8.5px;
    position: relative;
    right: 0px;
    text-decoration: none solid rgb(146, 146, 146);
    text-emphasis-color: rgb(146, 146, 146);
    top: 0px;
    transform-origin: 269.406px 8.5px;
    user-select: none;
    width: 503.812px;
    border: 0px none rgb(146, 146, 146);
    font: 14px Times;
    margin: 0px 0px 12px;
    outline: rgb(146, 146, 146) none 0px;
    padding: 0px 0px 0px 35px;
}/*#LABEL_27*/

#LABEL_27:after {
    border-block-end-color: rgb(146, 146, 146);
    border-block-start-color: rgb(146, 146, 146);
    border-inline-end-color: rgb(146, 146, 146);
    border-inline-start-color: rgb(146, 146, 146);
    caret-color: rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    column-rule-color: rgb(146, 146, 146);
    cursor: pointer;
    text-decoration: none solid rgb(146, 146, 146);
    text-emphasis-color: rgb(146, 146, 146);
    user-select: none;
    border: 0px none rgb(146, 146, 146);
    font: 14px Times;
    outline: rgb(146, 146, 146) none 0px;
}/*#LABEL_27:after*/

#LABEL_27:before {
    border-block-end-color: rgb(146, 146, 146);
    border-block-start-color: rgb(146, 146, 146);
    border-inline-end-color: rgb(146, 146, 146);
    border-inline-start-color: rgb(146, 146, 146);
    caret-color: rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    column-rule-color: rgb(146, 146, 146);
    cursor: pointer;
    text-decoration: none solid rgb(146, 146, 146);
    text-emphasis-color: rgb(146, 146, 146);
    user-select: none;
    border: 0px none rgb(146, 146, 146);
    font: 14px Times;
    outline: rgb(146, 146, 146) none 0px;
}/*#LABEL_27:before*/

#INPUT_28 {
    block-size: 0px;
    border-block-end-color: rgb(0, 0, 0);
    border-block-end-style: none;
    border-block-end-width: 0px;
    border-block-start-color: rgb(0, 0, 0);
    border-block-start-style: none;
    border-block-start-width: 0px;
    border-inline-end-color: rgb(0, 0, 0);
    border-inline-end-style: none;
    border-inline-end-width: 0px;
    border-inline-start-color: rgb(0, 0, 0);
    border-inline-start-style: none;
    border-inline-start-width: 0px;
    bottom: 11px;
    cursor: pointer;
    display: block;
    height: 0px;
    inline-size: 0px;
    inset-block-end: 11px;
    inset-block-start: 0px;
    inset-inline-end: 496.812px;
    inset-inline-start: 35px;
    left: 35px;
    margin-block-end: 3px;
    margin-block-start: 3px;
    margin-inline-end: 3px;
    margin-inline-start: 4px;
    opacity: 0;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-inline-end: 0px;
    padding-inline-start: 0px;
    perspective-origin: 0px 0px;
    position: absolute;
    right: 496.812px;
    top: 0px;
    transform-origin: 0px 0px;
    user-select: none;
    width: 0px;
    background: rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(0, 0, 0);
    margin: 3px 3px 3px 4px;
    padding: 0px;
}/*#INPUT_28*/

#INPUT_28:after {
    cursor: pointer;
    user-select: none;
}/*#INPUT_28:after*/

#INPUT_28:before {
    cursor: pointer;
    user-select: none;
}/*#INPUT_28:before*/

#SPAN_29 {
    block-size: 15px;
    border-block-end-color: rgb(146, 146, 146);
    border-block-start-color: rgb(146, 146, 146);
    border-end-end-radius: 20%;
    border-end-start-radius: 20%;
    border-inline-end-color: rgb(146, 146, 146);
    border-inline-start-color: rgb(146, 146, 146);
    border-start-end-radius: 20%;
    border-start-start-radius: 20%;
    bottom: 2px;
    caret-color: rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    column-rule-color: rgb(146, 146, 146);
    cursor: pointer;
    display: block;
    height: 15px;
    inline-size: 15px;
    inset-block-end: 2px;
    inset-block-start: 0px;
    inset-inline-end: 518.438px;
    inset-inline-start: 5.375px;
    left: 5.375px;
    perspective-origin: 7.5px 7.5px;
    position: absolute;
    right: 518.438px;
    text-decoration: none solid rgb(146, 146, 146);
    text-emphasis-color: rgb(146, 146, 146);
    top: 0px;
    transform-origin: 7.5px 7.5px;
    user-select: none;
    width: 15px;
    background: rgb(33, 150, 243) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(146, 146, 146);
    border-radius: 20%;
    font: 14px Times;
    outline: rgb(146, 146, 146) none 0px;
}/*#SPAN_29*/

#SPAN_29:after {
    block-size: 7px;
    border-block-end-color: rgb(255, 255, 255);
    border-block-end-style: solid;
    border-block-end-width: 3px;
    border-block-start-color: rgb(255, 255, 255);
    border-block-start-style: solid;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-end-style: solid;
    border-inline-end-width: 3px;
    border-inline-start-color: rgb(255, 255, 255);
    border-inline-start-style: solid;
    bottom: 3px;
    caret-color: rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    column-rule-color: rgb(146, 146, 146);
    content: '""';
    cursor: pointer;
    display: block;
    height: 7px;
    inline-size: 3px;
    inset-block-end: 3px;
    inset-block-start: 2px;
    inset-inline-end: 4px;
    inset-inline-start: 5px;
    left: 5px;
    perspective-origin: 3px 5px;
    position: absolute;
    right: 4px;
    text-decoration: none solid rgb(146, 146, 146);
    text-emphasis-color: rgb(146, 146, 146);
    top: 2px;
    transform: matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0);
    transform-origin: 3px 5px;
    user-select: none;
    width: 3px;
    border-top: 0px solid rgb(255, 255, 255);
    border-right: 3px solid rgb(255, 255, 255);
    border-bottom: 3px solid rgb(255, 255, 255);
    border-left: 0px solid rgb(255, 255, 255);
    font: 14px Times;
    outline: rgb(146, 146, 146) none 0px;
}/*#SPAN_29:after*/

#SPAN_29:before {
    border-block-end-color: rgb(146, 146, 146);
    border-block-start-color: rgb(146, 146, 146);
    border-inline-end-color: rgb(146, 146, 146);
    border-inline-start-color: rgb(146, 146, 146);
    caret-color: rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    column-rule-color: rgb(146, 146, 146);
    cursor: pointer;
    text-decoration: none solid rgb(146, 146, 146);
    text-emphasis-color: rgb(146, 146, 146);
    user-select: none;
    border: 0px none rgb(146, 146, 146);
    font: 14px Times;
    outline: rgb(146, 146, 146) none 0px;
}/*#SPAN_29:before*/



